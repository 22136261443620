import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  /* redirects... */
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/auth',
    redirect: '/auth/sign-in'
  },
  /* app paths */
  {
    path: '/dashboard',
    component: () => import ('../views/DashboardPage.vue')
  },
  {
    path: '/services/:id',
    component: () => import('../views/ServiceDetails.vue')
  },
  /* auth paths */
  {
    path: '/auth/sign-in/:key?',
    component: () => import ('../views/auth/SignInPage.vue')
  },
  {
    path: '/auth/sign-up/:key?',
    component: () => import ('../views/auth/SignUpPage.vue')
  },
  /* sso path... */
  {
    path: '/sso/:key',
    component: () => import('../views/sso/SSOPage.vue')
  },
  {
    path: '/:pathMatch(.*)',
    redirect: '/dashboard',
  },
  {
    path: '/auth/:pathMatch(.*)',
    redirect: '/auth/sign-in',
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

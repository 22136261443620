
import { defineComponent } from 'vue'
import { IonAvatar, IonCol, IonContent, IonIcon, IonRow, IonPage } from '@ionic/vue';
import { arrowForwardOutline, chevronDownOutline, exitOutline, logOutOutline } from 'ionicons/icons';
import { get_cookie } from '@/utils/helper';
import * as config_data from '@/../config.json';
import { useRouter } from 'vue-router';
import { UserDocument } from '@/utils/interfaces';


const config = config_data

export default defineComponent({
    components: { IonAvatar, IonCol, IonContent, IonIcon, IonRow, IonPage },
    created() {
        const _user: string | null  = get_cookie(config.access.user)
        const _token: string | null = get_cookie(config.access.key)

        let isInvalid = (str: string | null) => {
            return (str == null || str == '')
        }

        if (isInvalid(_user) || isInvalid(_token)) {
            const router = useRouter()
            router.replace('/auth')
        } else {
            this.user = JSON.parse(_user ?? '');   
            this.$emit("user", JSON.parse(_user ?? ''))
            this.$emit("token", _token ?? '')
            this.$emit("complete")
        }

    },
    methods: {
        getHelp() {
            window.open('mailto: hello@opencider.com')
        },
        signOutPage() {
            localStorage.clear()
            this.$router.replace('/auth')
        },
    },
    data() {
        return {
            arrowForwardOutline,
            chevronDownOutline,
            exitOutline,
            logOutOutline,
            user: {} as UserDocument,
            welcomePage: config.welcome.url
        }
    }
})

import * as imageConversion from 'image-conversion';

export const avatars: Array<string> = [
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-1.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-2.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-3.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-4.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-5.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-6.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-7.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-8.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-9.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-10.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-11.png',
    'https://oc-space.fra1.digitaloceanspaces.com/users/avatar-12.jpg',
]

export async function createFile(path: string, name: string, type: string): Promise<File> {
    const response = await fetch(path);
    const data = await response.blob();
    const metadata = {
        type: type
    };


    return new File([data], name, metadata);
}



export async function fileToCompressedBlob(file: File): Promise<Blob> {
    return imageConversion.compress(file,{
        size: 100,
        quality: 0.8,
        width: 200,
        height: 200,
        orientation:2,
        scale: 0.5,
      })
}

export function pascalToSentence(str: string) {
    return str.replace(/([A-Z])/g, " $1").replace(/^./, function(str) {
      return str.toUpperCase();
    });
}

export function returnDataURIBlob(file: Blob, callback: (str: string) => void) {
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result as string)
    };
    reader.readAsDataURL(file);
}

export function returnDataURI(file: File, callback: (str: string) => void) {
    // Encode the file using the FileReader API
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result as string)
    };
    reader.readAsDataURL(file);
}

export function formatNumeric(str: string | number) {
    const query  = str.toString().split('').reverse()
    const length = query.length

    const array    = query.map((el, i) => {
        const val  = (i + 1)
        const cond = val % 3 === 0 && val < length
        let resp   = el

        if (cond) resp = resp + " "

        return resp
    })
    
    const result = array.join('')
    .split('')
    .reduce((prev,next) => next+prev)
    
    return result
}

export function isMalicious(str: string) {
    return str.length > 0 && str.includes("script")
}

import * as config_data from '@/../config.json';
import { useRouter } from 'vue-router';

const config = config_data


export function bake_cookie(user: string, token: string) {
    localStorage.setItem(config.access.user, user)
    localStorage.setItem(config.access.key, token)
}

export function get_cookie(key: string): string | null {

    if (key == config.access.key) {
        return localStorage.getItem(config.access.key)
    } else if (key == config.access.user) {
        return localStorage.getItem(config.access.user)
    } else return null;
}

export function delete_cookie() {
    localStorage.clear()
}

export function invalidReroute() {
    localStorage.clear()
    const router = useRouter()
    router.replace('/auth/sign-in')
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b45f2c7a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-padding" }
const _hoisted_2 = {
  href: "/dashboard",
  style: {"text-decoration":"none","color":"var(--ion-color-dark)"}
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_logo_text = _resolveComponent("logo-text")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { color: "light" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_row, { class: "nav-adjust" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, {
                  "size-md": "10.7",
                  "size-xs": "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("a", _hoisted_2, [
                      _createVNode(_component_logo_text)
                    ])
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  "size-md": "1",
                  "size-xs": "0"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_col, {
                          "size-md": "6",
                          "size-xs": "0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              button: "true",
                              onClick: _ctx.signOutPage,
                              icon: _ctx.logOutOutline,
                              class: "hidden log-out"
                            }, null, 8, ["onClick", "icon"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_col, {
                          "size-md": "6",
                          "size-xs": "0"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_avatar, { class: "avatar hidden" }, {
                              default: _withCtx(() => [
                                _createElementVNode("img", {
                                  alt: "User Avatar",
                                  src: _ctx.user.avatar
                                }, null, 8, _hoisted_3)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("div", null, [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}